import React from 'react'
import Moment from 'react-moment'
import 'moment/locale/es';
import getSymbolFromCurrency from "currency-symbol-map"
import { Tag } from 'rsuite';
import { Link } from 'react-router-dom'

export const getColumns = (userSetting, isActives) => {
    return [
        {
            name: "Id",
            selector: "idOriginTransaction",
            sortable: false,
            width: "150px",
            center: true,
            cell: (row) => (
                <Link
                    className="btn_link fw-700"
                    to={`/subscriptions-details/${row.status}/${row.idSubscription}`}
                >
                    {row.idOriginTransaction}
                </Link>
            ),
        },
        {
            name: "Referencia",
            selector: "referenceNumber",
            sortable: false,
            cell: (row) => (
                <Link
                    className="btn_link fw-700"
                    to={`/subscriptions-details/${row.status}/${row.idSubscription}`}
                >
                    {row.referenceNumber}
                </Link>
            ),
        },
        {
            name: isActives ? "Fecha de creación" : "Fecha de cancelación ",
            selector: "date",
            sortable: false,
            format: (row) => (
                <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">
                    {row.active ? row.date : row.modificationDate}
                </Moment>
            ),
        },
        {
            name: "Frecuencia de cobro",
            selector: "frequency",
            sortable: false,
            cell: (row) => (
                <span>
                    {
                        {
                            Daily: "Diario",
                            Biweekly: "Quincenal",
                            Monthly: "Mensual",
                            Halfyearly: "Semestral",
                            Annual: "Anual",
                        }[row.frequency]
                    }
                </span>
            ),
        },
        {
            name: "Monto",
            selector: "amount",
            sortable: false,
            format: (row) =>
                getSymbolFromCurrency(userSetting.isoCurrency) +
                row.amount.toLocaleString(userSetting.cultureCode, {
                    minimumFractionDigits: 2,
                }),
        },
        {
            name: "Estatus",
            selector: "active",
            cell: (row) =>
                <span><Tag color={row.status == "Activa" ? "green" : row.status == "Cancelada" ? "red" : "orange"}>{row.status} {row.linkToReplaceCard ?" - En proceso de remplazo" :""}</Tag></span>,
        },
    ];
}