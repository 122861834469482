import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { TitleSection } from "../../components/titleSection";
import { NewLeagues } from "./newLeagues";
import { CalendarDisabledDate } from "../../components/myCalendar";
import {
    Row,
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    Icon,
    Button,
    InputGroup,
    InputNumber,
    SelectPicker,
    Schema,
    Radio,
    RadioGroup,
    Notification,
    Message,
    Toggle,
} from "rsuite";
import { useSelector } from "react-redux";
import { transactionService } from "../../services/transaction.service";
import moment from "moment";
import "./paidLeagues.scss";
import { FormProvider } from "react-hook-form";

export const LeaguesSingle = (props) => {
    const userSetting = useSelector((state) => state.userSetting);
    const location = useLocation();
    const [dates, setDates] = useState({
        initDate: new Date(),
        endDate: new Date(),
        initDateExpiration: new Date(),
    });

    useEffect(() => {
        transactionService.getCaptureLineDates().then((result) =>
            setDates({
                initDate: moment(result.initDate),
                endDate: moment(result.endDate),
            })
        );
    }, []);

    const data = [
        {
            label: userSetting.isoCurrency,
            value: userSetting.isoCurrency,
        }
    ];

    const { StringType, NumberType, DateType } = Schema.Types;

    const model = Schema.Model({
        referenceNumber: StringType().isRequired("Este campo es requerido."),
        email: StringType()
            .isEmail(
                "Por favor, introduce una dirección de correo electrónico válida."
            )
            .isRequired("Este campo es requerido."),
        phoneNumber: NumberType().pattern(
            /^[0-9]{10}$/,
            "Indica un número telefónico valido."
        ),
        amount: NumberType()
            .isRequired("Este campo es requerido.")
            .min(1, "Este campo debe ser mayor a 0.")
            .max(1000000, "Este campo debe ser menor a 1,000,000."),
        currency: StringType().isRequired("Este campo es requerido."),
        expirationDate: DateType().isRequired("Este campo es requerido."),
    });

    const modelPaymentProcess = Schema.Model({
        frequency: StringType().isRequired(
            "Este campo es requerido."
        ),
    });
    const modelIsInstallments = Schema.Model({
        numberOfPays: NumberType()
            .isRequired("Este campo es requerdio")
            .min(2, "Este campo debe ser mayor a 1.")
            .max(1000000, "Este campo debe ser menor a 1,000,000."),
        nextPaymentDate: DateType().isRequired("Este campo es requerido.")
    });
    const [modelGeneral, setModelGeneral] = useState(model);

    const [formValue, setFormValue] = useState({
        currency: userSetting.isoCurrency,
        paymentProcess: {
            subscription: {
                numberOfPays: 0,
                nextPaymentDate: null,
                frequency: null
            },
            offsetExpiration: 0,
        },
        expirationDate: null,
        nextPaymentDateEditable: false,
        typeFrequency: 'isNone',
        error: "",
    });
    const [frecuencyValue, setFrecuencyValue] = useState(null);

    const handleSubmit = (formStatus) => {
        if (formStatus) {
            const expirationDate = moment(formValue.expirationDate);
            const nextPaymentDate =
                formValue.paymentProcess.subscription.nextPaymentDate != null
                    ? moment(formValue.paymentProcess.subscription.nextPaymentDate)
                    : null;
            if (nextPaymentDate != null ? expirationDate < nextPaymentDate : true) {
                formValue.paymentProcess.offsetExpiration = expirationDate.diff(
                    dates.initDate,
                    "days"
                );
                transactionService.createCaptureLine(formValue).then((result) => {
                    if (result.success) {
                        Notification.success({
                            title: "La liga de pago se creó correctamente.",
                            duration: 5000,
                        });
                        props.history.push("/paid-leagues");
                    } else {
                        setFormValue({ ...formValue, error: result.message });
                    }
                });
            } else {
                Notification.error({
                    title: "Fecha del siguiente cobro mayor a fecha de vencimiento",
                    duration: 5000,
                });
            }
        }
    };

    const [frequencyOptions] = useState([
        { value: "Daily", label: "Diario" },
        { value: "Biweekly", label: "Quincenal" },
        { value: "Monthly", label: "Mensual" },
        { value: "Halfyearly", label: "Semestral" },
        { value: "Annual", label: "Anual" },
    ]);

    const changeRecurrence = (value) => {
        let formValueLet = formValue;
        formValueLet.paymentProcess.subscription.numberOfPays = 0;
        formValueLet.paymentProcess.subscription.nextPaymentDate = null;
        formValueLet.paymentProcess.subscription.frequency = null;
        formValueLet.typeFrequency = 'isNone';

        if (value == 'isRecurring') {
            formValueLet.typeFrequency = value;
            const modelCombine = Schema.Model.combine(model, modelPaymentProcess);
            setModelGeneral(modelCombine);
        } else if (value == 'isInstallments') {
            formValueLet.typeFrequency = value;
            const modelCombine = Schema.Model.combine(model, modelIsInstallments);
            const modelInstallments = Schema.Model.combine(modelCombine, modelPaymentProcess);
            setModelGeneral(modelInstallments);
        }
        else {
            setModelGeneral(model);
        }
        setFormValue(formValueLet);
        onChangeFrequency(null);
    }

    const onChangeFrequency = (value) => {
        let formValueLet = formValue;
        formValueLet.paymentProcess.subscription.frequency = value;
        formValueLet.frequency = value;
        setFormValue(formValueLet);
        setFrecuencyValue(value);
    };

    const setDateExpiration = (value) => {
        let formValueLet = formValue;
        let dateinit = new Date(value);
        dateinit.setDate(dateinit.getDate() + 1);
        setDates({ initDateExpiration: dateinit });
        formValueLet.expirationDate = value;
        formValueLet.nextPaymentDateEditable = true;
        setFormValue(formValueLet);
    };

    const setDateNextExpiration = (value) => {
        let formValueLet = formValue;
        formValueLet.paymentProcess.subscription.nextPaymentDate = value;
        formValueLet.nextPaymentDate = value;

        setFormValue(formValueLet);
    };

    const setnumberOfPays = (value) => {
        let formValueLet = formValue;
        formValueLet.paymentProcess.subscription.numberOfPays = value;
        formValueLet.numberOfPays = value;
        setFormValue(formValueLet);
    };

    const recurrence = () => {
        if (formValue.typeFrequency == "isRecurring" || formValue.typeFrequency == "isInstallments") {
            return <FlexboxGrid.Item
                className="mb-4"
                componentClass={Col}
                colspan={24}
                sm={8}
                md={8}
            >
                <FormGroup>
                    <ControlLabel>
                        Selecciona{" "}
                        {formValue.typeFrequency == 'isRecurring'
                            ? "recurrencia"
                            : "periodicidad"}
                    </ControlLabel>
                    <FormControl
                        accepter={SelectPicker}
                        data={frequencyOptions}
                        block
                        value={frecuencyValue}
                        name="frequency"
                        searchable={false}
                        placeholder="Seleccione"
                        onChange={(value) => onChangeFrequency(value)}
                    />
                </FormGroup>
            </FlexboxGrid.Item>;
        } else { return ''; }
    };

    return (
        <section className="wrapper_paid_leagues">
            <div className="mb-4">
                <Link to={location.state && location.state.origin ? "/Subscriptions" : "/paid-leagues"}>
                    <Button className="btn c_primary p0" appearance="link" type="submit">
                        <Icon icon="chevron-left" />
                        <span className="fw-400"> Regresar</span>
                    </Button>
                </Link>
            </div>
            <Row>
                <Col sm={24} md={16}>
                    <TitleSection title="Ligas de pago" />
                    <h3 className="fw-300 mt-3">Generar liga de pago única</h3>
                </Col>
                <Col sm={24} md={8}>
                    <div className="text-right mt-3">
                        <NewLeagues />
                    </div>
                </Col>
            </Row>

            <Form
                fluid
                formValue={formValue}
                onChange={(formValue) => setFormValue(formValue)}
                model={modelGeneral}
                onSubmit={handleSubmit}
            >
                <Row>
                    <Col md={8} sm={24}>
                        <div className="cards_form cards">
                            <FlexboxGrid>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                >
                                    <h6 className="fw-300 bg_ttls_labels">
                                        <span>Información general</span>
                                    </h6>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                >
                                    <FormGroup>
                                        <ControlLabel>Referencia</ControlLabel>
                                        <FormControl name="referenceNumber" size="lg" />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                >
                                    <FormGroup>
                                        <ControlLabel>Correo electrónico</ControlLabel>
                                        <InputGroup>
                                            <InputGroup.Addon>@</InputGroup.Addon>
                                            <FormControl name="email" type="email" size="lg" />
                                        </InputGroup>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                >
                                    <FormGroup>
                                        <ControlLabel>Número telefónico</ControlLabel>
                                        <InputGroup>
                                            <InputGroup.Addon>#</InputGroup.Addon>
                                            <FormControl
                                                accepter={InputNumber}
                                                name="phoneNumber"
                                                size="lg"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </div>
                    </Col>
                    <Col md={16} sm={24}>
                        <div className="cards_form cards">
                            <FlexboxGrid>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                >
                                    <h6 className="fw-300 bg_ttls_labels">
                                        <span>Detalle orden de pago</span>
                                    </h6>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={8}
                                    md={8}
                                >
                                    <FormGroup>
                                        <ControlLabel>Importe</ControlLabel>
                                        <InputGroup>
                                            <InputGroup.Addon>$</InputGroup.Addon>
                                            <FormControl
                                                accepter={InputNumber}
                                                name="amount"
                                                size="lg"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={8}
                                    md={8}
                                >
                                    <FormGroup>
                                        <ControlLabel>Moneda</ControlLabel>
                                        <FormControl
                                            accepter={SelectPicker}
                                            block
                                            size="lg"
                                            name="currency"
                                            data={data}
                                            searchable={false}
                                            placeholder="Seleccione"
                                            disabled
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    colspan={24}
                                    sm={8}
                                    md={8}
                                >
                                    <FormGroup>
                                        <ControlLabel>Fecha de vecimiento</ControlLabel>
                                        <FormControl
                                            accepter={CalendarDisabledDate}
                                            name="expirationDate"
                                            initDate={dates.initDate}
                                            endDate={dates.endDate}
                                            days={userSetting.offsetExpiration}
                                            onChange={(value) => setDateExpiration(value)}
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} colspan={24} sm={24}>
                                    <h6 className="fw-300 bg_ttls_labels mb-4 mt-4">
                                        <span>Frecuencia</span>
                                    </h6>
                                </FlexboxGrid.Item>

                                <FormGroup controlId="radioList" className="mb-1">
                                    <RadioGroup name="radioList" inline value={formValue.typeFrequency} onChange={(value) => changeRecurrence(value)}>
                                        <Radio value="isNone">Ninguna</Radio>
                                        <Radio value="isRecurring">Es recurrente</Radio>
                                        <Radio value="isInstallments">Pago a Plazos</Radio>
                                    </RadioGroup>
                                </FormGroup>

                                {/* {!formValue.isInstallments ? (
                  <FlexboxGrid.Item
                    className="mb-4"
                    componentClass={Col}
                    colspan={24}
                    sm={8}
                    md={8}
                  >
                    <FormGroup>
                      <ControlLabel>Es recurrente</ControlLabel>
                      <FormControl
                        accepter={Toggle}
                        name="isRecurring"
                        onChange={(value) => onIsRecurring(value)}
                      />
                    </FormGroup>
                  </FlexboxGrid.Item>
                ) : (
                  ""
                )}
                {!formValue.isRecurring ? (
                  <FlexboxGrid.Item
                    className="mb-4"
                    componentClass={Col}
                    colspan={24}
                    sm={8}
                    md={8}
                  >
                    <FormGroup>
                      <ControlLabel>Pago a Plazos</ControlLabel>
                      <FormControl
                        accepter={Toggle}
                        name="isInstallments"
                        onChange={(value) => onIsInstallments(value)}
                      />
                    </FormGroup>
                  </FlexboxGrid.Item>
                ) : (
                  ""
                )} */}
                                <FlexboxGrid.Item colspan={24} sm={24}>
                                    {formValue.typeFrequency && recurrence()}
                                    {formValue.typeFrequency == 'isInstallments' && (
                                        <>
                                            <FlexboxGrid.Item
                                                className="mb-4"
                                                componentClass={Col}
                                                colspan={24}
                                                sm={8}
                                                md={8}
                                            >
                                                <FormGroup>
                                                    <ControlLabel>Fecha del siguiente cobro</ControlLabel>
                                                    {!formValue.nextPaymentDateEditable ?
                                                        <FormControl
                                                            name="nextPaymentDate"
                                                            readOnly
                                                            accepter={CalendarDisabledDate}
                                                        />
                                                        :
                                                        <FormControl
                                                            name="nextPaymentDate"
                                                            initDate={dates.initDateExpiration}
                                                            accepter={CalendarDisabledDate}
                                                            onChange={(value) => setDateNextExpiration(value)}
                                                        />}
                                                </FormGroup>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item
                                                className="mb-4"
                                                componentClass={Col}
                                                colspan={24}
                                                sm={8}
                                                md={8}
                                            >
                                                <FormGroup>
                                                    <ControlLabel>Número de pagos</ControlLabel>
                                                    <FormControl
                                                        name="numberOfPays"
                                                        accepter={InputNumber}
                                                        onChange={(value) => setnumberOfPays(value)}
                                                    />
                                                </FormGroup>
                                            </FlexboxGrid.Item>
                                        </>
                                    )}
                                    <br />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                    md={24}>
                                    {formValue.error != "" ? (
                                        <Message type="error" description={formValue.error} />
                                    ) : null}
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item
                                    className="mb-4"
                                    componentClass={Col}
                                    colspan={24}
                                    sm={24}
                                    md={24}
                                >
                                    <FormGroup>
                                        <Button
                                            className="btn primary"
                                            appearance="primary"
                                            type="submit"
                                        >
                                            Generar liga de pago
                                        </Button>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </div>
                    </Col>
                </Row>
            </Form>
        </section >
    );
};
