import React, { useState } from "react";
import { TitleSection } from "../../components/titleSection";
import { FlexboxGrid, Row, Col, Icon } from "rsuite";
import "./helpCenter.scss";

export const HelpCenter = () => {
  const [activeTab, setActiveTab] = useState(1);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <section className="subscriptions_details">
      <TitleSection title="Centro de ayuda" />

      <FlexboxGrid className="mt-3" align="bottom">
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>
          <h3 className="fw-300">Estamos aquí para ayudarle</h3>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Row className="section_accordions" gutter={24}>
        <Col sm={24} md={15} lg={16}>
          <div>
            <h5 className="fw-600 mb-4">Preguntas frecuentes</h5>
            <div className="wrapper_accordion mb-5">
              <div className="item_accordion">
                <div
                  onClick={() => {
                    toggle(1);
                  }}
                  className="item_accordion_head"
                >
                  <h5>¿Qué es el ambiente de Pruebas?</h5>
                  <Icon icon="chevron-circle-down" />
                </div>
                <div
                  className={
                    "item_accordion_body " + (activeTab === 1 ? "is-open" : "")
                  }
                >
                  <p>
                    Con esta cuenta podrás probar todas las funcionalidades de
                    Red Pay sin realizar cargos ni pagos reales que impacten a
                    tu comercio, simulando los diferentes casos de éxito y error
                    al momento de procesar tus operaciones. 
                  </p>
									<p>Se utiliza este
                    ambiente para integrar y probar la página de e-commerce o App
                    e irte familiarizando con la plataforma.</p>
                </div>
              </div>
              <div className="item_accordion">
                <div
                  onClick={() => {
                    toggle(2);
                  }}
                  className="item_accordion_head"
                >
                  <h5>¿Qué es el ambiente de producción?</h5>
                  <Icon icon="chevron-circle-down" />
                </div>
                <div
                  className={
                    "item_accordion_body " + (activeTab === 2 ? "is-open" : "")
                  }
                >
                  <p>
                    Para empezar a procesar cargos reales deberás solicitar la
                    activación de tu comercio en el ambiente de producción. Este
                    proceso lo deberás contactar al gerente asignado de Red Pay,
                    donde se te pedirá llenar un formulario con la información
                    de tu empresa.
                  </p>
									<p> Revisaremos la información que nos envíes
                    internamente y en caso de que necesitemos más detalles nos
                    pondremos en contacto contigo.</p>
									<p> Una vez que aceptemos tu
                    solicitud te haremos llegar un correo electrónico para
                    activar tu cuenta y asignar una contraseña de acceso al
                    portal de producción.</p>
                </div>
              </div>
              <div className="item_accordion">
                <div
                  onClick={() => {
                    toggle(3);
                  }}
                  className="item_accordion_head"
                >
                  <h5>¿Qué es una integración?</h5>
                  <Icon icon="chevron-circle-down" />
                </div>
                <div
                  className={
                    "item_accordion_body " + (activeTab === 3 ? "is-open" : "")
                  }
                >
                  <p>
                    Es una implementación técnica que se realiza directo en tu
                    eCommerce para que puedas darles la posibilidad a tus
                    clientes de realizar pagos en línea por los diferentes
                    métodos que manejamos en Red Pay.
                  </p>
                </div>
              </div>
              <div className="item_accordion">
                <div
                  onClick={() => {
                    toggle(4);
                  }}
                  className="item_accordion_head"
                >
                  <h5>¿Qué es la validación técnica?</h5>
                  <Icon icon="chevron-circle-down" />
                </div>
                <div
                  className={
                    "item_accordion_body " + (activeTab === 4 ? "is-open" : "")
                  }
                >
                  <p>
                    Es el proceso mediante el cual se revisa que tu sitio web
                    cumpla con los estándares de seguridad solicitados por Red
                    Pay para otorgarte las llaves al ambiente de producción y
                    que puedas comenzar a procesar transacciones reales con tus
                    clientes.
                  </p>
                </div>
              </div>
              <div className="item_accordion">
                <div
                  onClick={() => {
                    toggle(5);
                  }}
                  className="item_accordion_head"
                >
                  <h5>¿Qué plugins para e-commerce soporta Red Pay?</h5>
                  <Icon icon="chevron-circle-down" />
                </div>
                <div
                  className={
                    "item_accordion_body " + (activeTab === 5 ? "is-open" : "")
                  }
                >
                  <p className="mb-2">
                    Red Pay cuenta con los siguientes plugins para e-commerce:
                  </p>
                  <ul>
                    <li>WooCommerce</li>
                    <li>Magento 2</li>
                    <li>Prestashop</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={9} lg={8}>
          <h5 className="fw-600 mb-4">Canales oficiales</h5>
          <Row>
            <Col sm={12} md={24}>
              <div className="cards mb-5">
                <p className="paragraph mb-3">Atención a cliente</p>
                <div className="mb-3 d_flex item_center">
                  <span className="ico_span">
                    <Icon icon="phone"></Icon>
                  </span>
                  <a href="tel:+5544333333">(553) 245 1456</a>
                </div>
                <div className="d_flex item_center">
                  <span className="ico_span">
                    <Icon icon="envelope-o"></Icon>
                  </span>
                  <a href="mailto:name@email.com">contacto@redpay.com.mx</a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={24}>
              <div className="cards ">
                <p className="paragraph mb-3">Online</p>
                <div className="mb-3 d_flex item_center">
                  <span className="ico_span">
                    <Icon icon="facebook"></Icon>
                  </span>
                  <a
                    href="https://www.facebook.com/Redpay.mx"
                    target="blank_"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </div>
                <div className="d_flex item_center">
                  <span className="ico_span">
                    <Icon icon="globe"></Icon>
                  </span>
                  <a
                    href="https://www.redpay.mx/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    soporte@redpay.com.mx
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};
